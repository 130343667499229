import classes from './donation-form.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';
import { useEffect, useRef } from 'react';

export const DonationForm = () => {
  const router = useRouter();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (iframeRef.current && e.origin === 'https://donate.raisenow.io') {
        iframeRef.current.style.height = e.data.height + 'px';
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={classes.root}>
      <iframe
        ref={iframeRef}
        className={classes.iframe}
        id="tamaro-iframe"
        allow="payment"
        src={`https://donate.raisenow.io/vgdrk?analytics.channel=embed&lng=${
          router.locale ?? FALLBACK_LOCALE
        }`}
        style={{ width: '100%', border: 0 }}
      ></iframe>
    </div>
  );
};
